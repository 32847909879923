import { useEffect, useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { useParams } from "react-router-dom";
import { resolveClassNames } from "palstek";
import { useQuery } from "../../hooks/query";
import { useQueryString } from "../../hooks/querystring";
import { RangeSelector } from "../generic/rangeSelector/RangeSelector";
import { getRangeString } from "../../helper/date";
import { Breadcrumb } from "../Breadcrumb";
import { ObservationGraph } from "./ObservationGraph";
import { ObservationTables } from "./ObservationTables";
import { ObservationForm } from "./ObservationForm";
import { DatatableMessage } from "../../types/DatatableMessage";

import styles from "./observation.module.scss";

const Observation = () => { // eslint-disable-line max-statements
    const [selectedObservationId, setSelectedObservationId] = useState(null);
    const { trigger }                                       = useTrigger();
    const params                                            = useParams();
    const { translate, currentLanguage }                    = useTranslation();
    const { selectedPlant, options, plants, observations, messagesData } = useBranch({
        messagesData:  ["messages"],
        selectedPlant: ["selectedPlant"],
        options:       ["queryOptions"],
        plants:        ["plants"],
        observations:  ["mappedObservations"]
    });

    const plantId =  params.plantId;
    const messages = plantId ? messagesData
        .filter(x => x.plant_id === plantId)
        .map(elem => new DatatableMessage(elem)) : [];

    const onDatePick = date => {
        trigger("applyOption", "range", date.name);
        trigger("applyOption", "calendar", date);
    };

    const onRowClick = elemId => {
        setSelectedObservationId(elemId);
    };

    const onClick = () => {
        trigger("fetchObservations", params.plantId);

        setSelectedObservationId(null);
    };

    const [filter] = useQueryString({
        options:  options,
        plants:   plants,
        optional: {
            range: x => (x ?? "").replace("undefined", "") || options.range || "last7Days"
        }
    });

    useQuery({
        options: filter,
        keys:    ["startTime", "range"]
    });

    useEffect(() => {
        trigger("applyPlant", params.plantId);
    }, []);

    useEffect(() => {
        trigger("fetchObservations", params.plantId);
    }, [options.calendar, options.range, params.plantId]);

    const items = [{ id: params.plantId, value: translate(selectedPlant?.name) }, { id: 1, value: translate("breadcrumb.favorites") }];

    useEffect(() => {
        if(observations.length === 0 && selectedObservationId) {
            setSelectedObservationId(null);
            return;
        }
        if(observations.length === 0 || selectedObservationId) return;

        setSelectedObservationId(observations[0].items[0].id);
    }, [observations]);

    const selectedObservation = observations.reduce((dest, elem) => dest.concat(elem.items), []).find(x => x.id === selectedObservationId);

    return (
        <div className={styles.detailView}>
            <div className={styles.mainContent}>
                <div className={styles.breadcrumb}>
                    <div className={styles.breadcrumbContainer}>
                        <Breadcrumb items={items}/>
                    </div>
                    <RangeSelector
                        range={options.range}
                        selected={options.calendar}
                        onChange={onDatePick}
                    />
                </div>
                <div className={styles.plant}>
                    <span className={styles.slot}>{ options.range === "shift" ? translate("overview.selector.shift") : getRangeString([options.calendar.from, options.calendar.until], currentLanguage)}</span>
                </div>
                <div className={styles.content}>
                    {
                        selectedObservationId &&
                        <ObservationGraph active={observations.reduce((dest, elem) => dest.concat(elem.items), []).find(x => x.id === selectedObservationId)}/>
                    }
                    {
                        !selectedObservationId &&
                        <div className={resolveClassNames(styles.graphContainer, "center")}>
                            <span>{ translate("observation.plot.placeholder")}</span>
                        </div>
                    }
                </div>
                <ObservationTables
                    selected={selectedObservationId}
                    onClick={onClick}
                    onSelect={onRowClick}
                />
            </div>
            <aside className={styles.sidebar}>
                {
                    !selectedObservationId && observations.length > 0 &&
                    <div className={styles.placeholder}>
                        <span className={styles.sidebarHead}>{ translate("observation.sidebar.headline.empty") }</span>
                        <span className={styles.text}>{ translate("observation.sidebar.text.empty") }</span>
                    </div>
                }
                {
                    selectedObservationId && observations.length > 0 &&
                    <ObservationForm
                        key={selectedObservation.id}
                        observation={selectedObservation}
                        message={messages.find(x => x.plant_id === selectedObservation.plant_id)}
                    />
                }
            </aside>
        </div>
    );
};

export { Observation };

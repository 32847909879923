import { App as BasicApp, sync, computed, translation, action, hasPermission, getLanguageBy } from "@circle/gestalt-app";
import { Plant } from "./types/Plant";
import { DatePicker } from "./types/DatePicker";
import { v4 as uuid } from "uuid";
import { isUuid, sortAsc } from "./helper/helper";
import { WritableStream } from "web-streams-polyfill/ponyfill";
import streamSaver from "streamsaver";
import { createBrowserHistory } from "history";
import { createQueryString } from "./hooks/query";
import { sort } from "./actions/files";
import md5 from "md5";

class App extends BasicApp {
    message_delete_settings = sync("message_delete_settings"); // eslint-disable-line camelcase
    message_receive_settings = sync("message_receive_settings"); // eslint-disable-line camelcase
    observations = sync("observations");
    cause_reports = sync("cause_reports"); // eslint-disable-line camelcase
    routines_messages_connections = sync("routines_messages_connections"); // eslint-disable-line camelcase
    manufacturers = sync("manufacturers", {
        readonly: true
    });

    observed_message_groups = sync("observed_message_groups"); // eslint-disable-line camelcase

    plants = sync("plants", {
        readonly: true
    });

    licenses = sync("licenses", {
        readonly: true
    });

    routines = sync("routines", {
        readonly: true
    });

    cause_categories = sync("cause_categories", { // eslint-disable-line camelcase
        readonly: true
    });

    translations = translation({
        de: "/translations/texts_de.yml",
        en: "/translations/texts_en.yml"
    });

    static limit = 10;
    static state = {
        stupidCache:              {},
        history:                  createBrowserHistory(),
        language:                 getLanguageBy(window.config.languages.map(x => x.value)),
        default:                  window.config.defaultLanguage,
        languages:                window.config.languages.map(x => x.value),
        sortingSettings:          { selected: "name", order: "asc", language: getLanguageBy(window.config.languages.map(x => x.value)) },
        monitorSortingSettings:   { selected: "startTime", order: "desc", language: getLanguageBy(window.config.languages.map(x => x.value)) },
        licenses:                 [],
        messages:                 [],
        message_delete_settings:  [], // eslint-disable-line camelcase
        message_receive_settings: [], // eslint-disable-line camelcase
        plants:                   [],
        observed_message_groups:  [], // eslint-disable-line camelcase
        manufacturers:            [],
        routines:                 [],
        observations:             [],
        cause_reports:            [], // eslint-disable-line camelcase
        cause_categories:         [], // eslint-disable-line camelcase
        groups:                   [],
        summary:                  {
            err:  0,
            info: 0,
            warn: 0,
            log:  0
        },
        pagination: {
            ungrouped: {
                overall: 0,
                page:    0,
                count:   10,
                limit:   App.limit
            },
            messages: {
                page:  0,
                count: 10,
                limit: App.limit
            },
            monitor: {
                warn:   { count: 10, page: 0, limit: App.limit },
                err:    { count: 10, page: 0, limit: App.limit },
                info:   { count: 10, page: 0, limit: App.limit },
                log:    { count: 10, page: 0, limit: App.limit },
                task:   { count: 10, page: 0, limit: App.limit },
                system: { count: 10, page: 0, limit: App.limit },
                groups: []
            }
        },
        summaries:    {},
        translations: {},
        filter:       {},
        search:       {
            value:     "",
            processed: {
                value: "",
                time:  -1
            },
            result: []
        },
        settingsPlantsSelection: [], // eslint-disable-line
        queryOptions:            {
            keyword:            [],
            mode:               "filter",
            messageTypes:       ["err", "task"],
            calendar:           DatePicker.of("yesterday"),
            timeframes:         "shifts",
            range:              "yesterday",
            filter:             null,
            selectedPlant:      null,
            limit:              null,
            type:               "frequency",
            mainGraphViewTypes: ["duration"]
        },
        monitor: {
            messages: [],
            graph:    [],
            groups:   [],
            isOpen:   [],
            sorting:  {
                property: "count",
                order:    "desc"
            },
            aggregates: {
                err:    [],
                task:   [],
                warn:   [],
                info:   [],
                system: [],
                log:    []
            }
        },
        ungroupedSorting: {
            property: "system",
            order:    "desc"
        },
        ungroupedKeywords: [],
        observation:       {
            graph:    [],
            messages: []
        },
        detail: {
            groups: []
        },
        ungrouped:         [],
        globalBusyCounter: 0,
        sidebarContent:    {
            history: {
                list:     [],
                messages: [],
                order:    {
                    property: "startTime",
                    value:    "asc"
                }
            },
            graph: []
        },
        failedRequest: [],
        plantId:       "",
        selectedPlant: {},
        joinCauses:    [],
        causeMessages: [],
        sorting:       {
            mechanism: "asc",
            column:    "err"
        },
        monitorStatistic:              [],
        graphMessages:                 [],
        breadcrumbsPath:               [],
        groupsOfMessage:               [],
        routines_messages_connections: [], // eslint-disable-line camelcase
        groupsGraph:                   []
    };

    // --- computations ---

    licensedPlants = computed({
        licenses:      ["licenses"],
        plants:        ["plants"],
        manufacturers: ["manufacturers"],
        user:          ["user"],
        translate:     ["translate"]
    }, data => {
        if(data.plants.length === 0 || data.manufacturers.length === 0) return [];
        const plants = data.plants.map(x => ({
            ...x,
            manufacturer: data.manufacturers.find(y => y.id === x.manufacturerId)
        }));

        if(hasPermission(data.user, "SKIP_LICENSE_CHECK")) return sortAsc(plants, "name", data.translate);

        const plantIds = data.licenses
            .reduce((dest, elem) => dest.concat(elem.licenses.filter(x => x.appId === "message-monitor")
            .filter(x => new Date(x.expiryDate) > new Date() || x.expiryDate !== null)
            .map(x => x.plantId)), []);

        return sortAsc([...new Set(plantIds)]
            .filter(elem => elem)
            .map(x => plants.find(elem => elem.id === x))
            .filter(x => x), "name", data.translate);
    });

    sortedGroups = computed({
        groups:  ["detail", "groups"],
        sorting: ["sorting"]
    }, data => {
        const groups = [...data.groups];

        groups.sort((a, b) => {
            if(data.sorting.mechanism === "hidden") return 0;
            if(data.sorting.mechanism === "desc")    return a.items.find(x => x.messageType === data.sorting.column).duration - b.items.find(x => x.messageType === data.sorting.column).duration;

            return b.items.find(x => x.messageType === data.sorting.column).duration - a.items.find(x => x.messageType === data.sorting.column).duration;
        });

        return groups;
    });

    messageReceiveSettings = computed({
        plants:   ["licensedPlants"],
        settings: ["message_receive_settings"]
    }, data => data.plants?.map(plant => {
        const currentSetting = data.settings.find(x => x.plant_id === plant.id) ?? {
            plant_id:        plant.id, // eslint-disable-line camelcase
            isInfoAllowed:   false,
            isSystemAllowed: false,
            isLogAllowed:    false
        };

        return { ...currentSetting, plant };
    }));

    plantsOverview = computed({
        messages: ["messages"],
        plants:   ["licensedPlants"]
    }, data => data.plants?.map(plant => new Plant(plant, data.messages)));

    sidebarHistory = computed({
        history: ["sidebarContent", "history"]
    }, data => {
        const { order, messages } = data.history;
        const content = [...messages];

        content.sort((a, b) => {
            const valueA = order.property === "startTime" ? new Date(a[order.property]).getTime() : a[order.property];
            const valueB = order.property === "startTime" ? new Date(b[order.property]).getTime() : b[order.property];

            return order.value === "asc" ? valueA - valueB : valueB - valueA;
        });

        return content;
    });

    sidebarHistoryChrono = computed({
        history: ["sidebarContent", "history"]
    }, data => {
        const { order, list } = data.history;
        const content = [...list];

        content.sort((a, b) => {
            const valueA = order.property === "startTime" ? new Date(a[order.property]).getTime() : a[order.property];
            const valueB = order.property === "startTime" ? new Date(b[order.property]).getTime() : b[order.property];

            return order.value === "asc" ? valueA - valueB : valueB - valueA;
        });

        return content;
    });

    mappedObservations = computed({
        translate:       ["translate"],
        queryOptions:    ["queryOptions"],
        fetched:         ["observation", "messages"],
        sortingSettings: ["sortingSettings"],
        observations:    ["observations"]
    }, data => {
        if(!data.queryOptions.selectedPlant && !data.observations) return [];
        const observe = data.fetched.map(x => ({
            ...x,
            items: sort({ items: x.items.filter(message =>
                // eslint-disable-next-line max-nested-callbacks
                data.observations.some(y =>
                    message.message === y.message &&
                    message.system === y.system &&
                    message.messageType === y.messageType &&
                    message.referenceObject === y.referenceObject
                )),
            property: data.sortingSettings.selected,
            ordering: data.sortingSettings.order,
            getter:   data.translate })
        }));

        return observe.filter(x => x.items.length >= 1);
    });

    routinesOfPlant = computed({
        plantId:  ["plantId"],
        routines: ["routines"]
    }, data => {
        // return data.routines?.filter(routine => routine.plants.find(plant => plant?.plantId === data?.plantId));
        return data.routines;
    });

    observedGroups = computed({
        selectedPlant:         ["selectedPlant"],
        observedMessageGroups: ["observed_message_groups"],
        allGroups:             ["sortedGroups"]
    }, data => {
        if(!data.selectedPlant || !data.allGroups) return [];
        const groups = (data.allGroups ?? []).filter(x => x.messageGroup !== null);

        const observationsFilterOnPlant = data.observedMessageGroups.filter(x => x.plantId === data.selectedPlant.id).map(y => y.messageGroup);

        return groups.filter(x => observationsFilterOnPlant.includes(x.messageGroup));
    });

    addObservedGroup = action((plantId, group) => {
        const result = {
            messageGroup: group,
            plantId:      plantId
        };

        this.state.push(["observed_message_groups"], result);
        this.state.commit();
    });

    deleteObservedGroup = action(group => {
        const idx = this.state
            .get("observed_message_groups")
            .findIndex(x => x.messageGroup === group);

        this.state.unset(["observed_message_groups", idx]);
        this.state.commit();
    });

    onBreadcrumbAdd = action((path, edit) => {
        if(edit) {
            const allBreadcrumbs = this.state.get("breadcrumbsPath");
            const updateBreadcrumbs = allBreadcrumbs.concat([path]);

            this.state.select("breadcrumbsPath").set(updateBreadcrumbs);
            this.state.commit();
            return;
        }
        this.state.select("breadcrumbsPath").set(path);
        this.state.commit();
        return;
    });

    setMonitorSorting = action(selected => {
        const currentFilter = this.state.get("monitorSortingSettings");
        const language      = this.state.get("locale");

        if(selected !== currentFilter.selected) {
            this.state.select("monitorSortingSettings").set({ selected, order: this.state.get("monitorSortingSettings").order, language });
            return this.state.commit();
        }

        const currentOrder  = currentFilter ? currentFilter.order : null;
        const order         = currentOrder === "desc" || currentOrder === null ? "asc" : "desc";

        this.state.select("monitorSortingSettings").set({ selected, order, language });
        return this.state.commit();
    });

    onSorting = action(selected => {
        const currentFilter = this.state.get("sortingSettings");
        const language      = this.state.get("locale");

        if(selected !== currentFilter.selected) {
            this.state.select("sortingSettings").set({ selected, order: this.state.get("sortingSettings").order, language });
            return this.state.commit();
        }

        const currentOrder  = currentFilter ? currentFilter.order : null;
        const order         = currentOrder === "desc" || currentOrder === null ? "asc" : "desc";

        this.state.select("sortingSettings").set({ selected, order, language });
        return this.state.commit();
    });

    joinCausesCategories = computed({
        causes:     ["cause_reports"],
        categories: ["cause_categories"]
    }, data => {
        const join = cause => {
            if(cause?.category?.length === 0) return cause;
            return Object.assign({}, cause, {
                categories: cause.category.map(category => {
                    return data?.categories?.find(x => x?.id === category?.category_id);
                })
            });
        };

        return data.causes?.map(cause => join(cause));
    });

    causesByPlant = computed({
        causes:       ["joinCausesCategories"],
        queryOptions: ["queryOptions"]
    }, data => data.causes?.filter(x => x.plant_id === data.queryOptions.selectedPlant?.id));

    // --- actions ---
    open = action(x => this.history(x));

    applyRoutinesMessage = action(async obj => {
        const settings = this.state.select("routines_messages_connections").get();
        const message = settings.find(x => x.hash === obj.hash);

        if(!message?.id) {
            await this.http("POST", `${window.config.apiUrl}/routines_messages_connections`, obj);

            return;
        }

        const index = settings.findIndex(x => x.hash === obj.hash);
        const objectToPush = {
            ...obj,
            routines: message.routines.concat(obj.routines),
            id:       message.id
        };

        this.state.set(["routines_messages_connections", index], objectToPush);

        this.state.commit();
        return;
    });

    toggleReceiveSetting = action(x => {
        if(!x.id) {
            this.state.push(["message_receive_settings"], x);
            return this.state.commit();
        }
        const settings = this.state.select("message_receive_settings").get();
        const index = settings.findIndex(y => y.id === x.id);

        this.state.set(["message_receive_settings", index], x);

        return this.state.commit();
    });

    setBusy = action(() => {
        const cursor  = this.state.select("globalBusyCounter");
        const current = cursor.get();

        cursor.set(current + 1);

        this.state.commit();
    });

    unsetBusy = action(() => {
        const cursor = this.state.select("globalBusyCounter");
        const current = cursor.get();

        cursor.set(current > 0 ? current - 1 : 0);

        this.state.commit();
    });

    createCauseReport = action((path, elem) => {
        this.log.debug(`Creating ${path} ${elem.name}...`);

        delete elem.id;

        this.state.push([path], {
            ...elem,
            messages: elem.messages.map(x => ({
                messageType:           x.type || x.messageType,
                referenceObject_Sign:  x.refObjSign || x.referenceObject_Sign, // eslint-disable-line camelcase
                referenceObject_Descr: x.refObjDescr || x.referenceObject_Descr, // eslint-disable-line camelcase
                system:                x.system,
                message:               x.text || x.message,
                messageEx:             x.additionalText || x.messageEx,
                index:                 x.index || x.id
            }))
        });

        this.state.commit();

        return this.history(window.location.pathname);
    });

    setSorting = action(newColumn => {
        const { mechanism, column } = this.state.get("sorting");
        const inter = column !== newColumn || mechanism === "asc" ? "desc" : "asc";

        this.state.select("sorting").set({
            mechanism: inter,
            column:    newColumn
        });
        this.state.commit();
    });

    plantIdSet = action(id => {
        this.state.select("plantId").set(id);
        this.state.commit();
    });

    setFilter = action(filter => {
        const cursor   = this.state.select("filter");
        const previous = cursor.get();

        if(filter.order) {
            cursor.set(filter);
            return this.state.commit();
        }

        cursor.set({
            order:    filter.selected === previous.selected ? this.toggleOrder(previous.order) : "desc",
            selected: filter.selected
        });

        return this.state.commit();
    });

    toggleObservation = action(message => {
        const observations = this.state.get("observations");
        const index        = observations.findIndex(x => x.plant_id === message.plant_id &&
            x.messageType === message.messageType &&
            x.system === message.system &&
            x.message === message.message &&
            x.referenceObject === message.referenceObject);

        const current = observations[index];

        if(current) {
            this.state.unset(["observations", index]);
            return this.state.commit();
        }

        delete message.id;

        this.state.select("observations").push(message);
        return this.state.commit();
    });

    toggleMessageType = action(messageType => {
        const types = this.state.get(["monitor", "isOpen"]);

        const value = [].concat(messageType).reduce((dest, elem) => {
            if(dest.includes(elem)) return dest.filter(x => x !== elem);

            return dest.concat(elem);
        }, types);

        this.state.select("monitor", "isOpen").set(value);
        this.state.commit();
    });

    setQueryOptions = action(data => {
        const previous = this.state.get("queryOptions");

        this.state.select("queryOptions").set({
            ...previous,
            ...data
        });

        this.state.commit();
    });

    sortUngroupedMessages = action((plantId, messageGroup, data) => {
        this.state.select("ungroupedSorting").set(data);

        this.state.commit();

        this.trigger("fetch", plantId, { reset: true }, messageGroup);
    });

    sort = action((plantId, messageGroup, data) => {
        this.state.select("monitor", "sorting").set(data);

        this.state.commit();

        this.trigger("fetch", plantId, { reset: true }, messageGroup);
    });

    onFilter = action((id, messageGroup) => {
        const opts     = this.state.get("queryOptions");

        const options = {
            ...opts,
            mode: (!opts.mode || opts.mode === "normal") ? "filter" : "normal"
        };

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);

        const doFetch = opts.selectedPlant;

        if(!doFetch) return;

        this.trigger("fetch", id, { reset: true, onlyDatatable: true }, messageGroup);
    });

    onMessageTypeFilter = action(key => {
        const doFetch = this.applyMessageType(key);

        if(!doFetch) return;

        this.trigger("fetch", null, { reset: true, onlyDatatable: true });
    });

    onCalendarSelect = action(calendarElem => {
        const newOptions = this.applyOption("calendar", calendarElem);

        if(!newOptions) return;

        this.trigger("fetch", null, { reset: true, onlyDatatable: true });
    });

    onMainViewChange = action(types => {
        this.applyMainGraphView(types);
    });

    onPlantSelect = action((plantId, path) => {
        if(!plantId) return;

        const plant = this.state.get("licensedPlants").find(x => x.id === plantId);

        this.trigger("applyPlant", plantId, path);

        if(plant) return;

        this.state.select("summary").set({
            err: {
                count:    0,
                duration: 0
            },
            info: {
                count:    0,
                duration: 0
            },
            log: {
                count:    0,
                duration: 0
            },
            warn: {
                count:    0,
                duration: 0
            }
        });

        this.state.commit();
    });

    applyMessageGroups = action(async(groups, messages) => {
        await Promise.all(groups.map(group =>
            this.http("POST", `${window.config.apiUrl}/message_groups`, group)));

        const list    = this.state.get("ungrouped");
        const newList = list.filter(obj => !messages.map(item => item.id).includes(obj.id));

        this.state.select("ungrouped").set(newList);
        this.state.select(["pagination", "ungrouped", "count"]).set(newList.length);
        this.state.commit();
        this.trigger("fetchMessageGroups");
    });

    applyGroupingMessage = action(async groups => {
        const previous = this.state.get("groupsOfMessage");

        await Promise.all(groups.map(group =>
            this.http("POST", `${window.config.apiUrl}/message_groups`, group)));
        this.state.select("groupsOfMessage").set(previous.concat(groups.map(x => ({
            ...x,
            id:   Date.now() + Math.random(),
            hash: md5(`${x.system}${x.message}${x.messageType}${x.referenceObject_Sign}${x.referenceObject_Descr}${x.plant_id}`)
        }))));
        this.state.commit();
    });

    addPlantToSettingsSelection = action(plantId => {
        this.state.select("settingsPlantsSelection").push(plantId);
        this.state.commit();
    });

    removePlantFromSettingsSelection = action(plantId => {
        const cursor = this.state.select("settingsPlantsSelection");

        if(plantId === "all") {
            cursor.set([]);
            this.state.commit();
        }

        cursor.splice([cursor.get().indexOf(plantId), 1]);
        return this.state.commit();
    });

    reset = action(() => {
        const previousOptions = this.state.select("queryOptions").get();

        this.state.select("search").set({
            value:     "",
            processed: {
                value: "",
                time:  -1
            },
            result: []
        });
        const options = {
            ...previousOptions,
            selectedPlant: null
        };

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
    });

    search = action(async(val, doFetch = true) => {
        const value     = val instanceof Array ? val[0] : val;
        const pointer   = this.state.select("search");
        const timestamp = Date.now();
        const data      = this.state.get("search");
        const opts     = this.state.get("queryOptions");

        const options = {
            ...opts,
            keyword: value ? [...new Set([value])] : []
        };

        pointer.set(Object.assign({}, data, {
            value
        }));

        if(data.processed.timestamp > timestamp) return this.state.commit();

        this.state.select("pagination", "monitor").set({
            groups: [],
            warn:   { count: 10, page: 0, limit: App.limit },
            err:    { count: 10, page: 0, limit: App.limit },
            info:   { count: 10, page: 0, limit: App.limit },
            log:    { count: 10, page: 0, limit: App.limit },
            task:   { count: 10, page: 0, limit: App.limit },
            system: { count: 10, page: 0, limit: App.limit }
        });

        if(doFetch) await this.trigger("fetch", null, { reset: true });

        this.state.select("search").set(Object.assign({}, this.state.get("search"), {
            processed: {
                value:     value,
                timestamp: timestamp
            }
        }));

        this.trigger("setQueryOptions", options);
        return this.trigger("applyQuery", options);
    });

    orderHistoryBy = action(orderBy => {
        const switchAscDesc = (curProp, newProp, curOrder) => {
            if(curProp !== newProp) return "asc";

            return curOrder === "asc" ? "desc" : "asc";
        };

        const currentOrder = this.state.get(["sidebarContent", "history", "order"]);
        const order        = {
            property: orderBy,
            value:    switchAscDesc(currentOrder.property, orderBy, currentOrder.value)
        };

        this.state.select(["sidebarContent", "history", "order"]).set(order);
        this.state.commit();
    });

    resolveError = action(() => {
        this.state.get("failedRequest").forEach(e =>
            this.trigger("retrieve", e.type, e.target, e.filters)
        );
        this.state.select("failedRequest").set([]);
        this.state.commit();
    });

    cleanup = action(target => {
        this.select(target).set([]);
        this.state.commit();
    });

    fetchMessageGroups = action((isLazy = false) => {
        const cacheCursor = this.state.select(["stupidCache", "fetchMessageGroups"]);
        const shouldUseCache = isLazy && cacheCursor.get()?.timestamp >= new Date().getTime() - 1000 * 30;

        if(shouldUseCache)
            return;

        cacheCursor.set({ timestamp: new Date().getTime() });
        this.trigger("setBusy");
        const options = this.state.get("queryOptions");

        this.trigger("retrieveAsync", "messageGroups", ["detail", "groups"], {
            range:      options.range,
            startTime:  `[${options.calendar.from.getTime() / 1000},${options.calendar.until.getTime() / 1000}]`,
            plant_id:   options.selectedPlant.id, // eslint-disable-line camelcase
            keyword:    `[${options.keyword.map(x => `"${x}"`).join(",")}]`,
            timeframes: options.timeframes
        });
        this.trigger("unsetBusy");
    });

    fetchGroupsOfMessage = action(async(hash, plantId) => {
        if(!hash || !plantId) return;
        const url = this.state._gestalt._rootUrl;
        const groups = await this.http("GET", `${url}/message_groups?hash="${hash}"&plant_id="${plantId}"`).then(x => x.json());

        this.state.select("groupsOfMessage").set(groups);
        this.state.commit();
        return;
    });

    fetchMonitorStatistic = action(async(plantId, messageGroup, lazy = false) => {
        const cacheCursor = this.state.select(["stupidCache", "fetchMonitorStatistic"]);

        if(lazy && cacheCursor.get()?.timestamp >= new Date().getTime() - 1000 * 30)
            return;

        cacheCursor.set({ timestamp: new Date().getTime() });

        this.trigger("setBusy");
        const translate = this.state.get("translate");
        const options = this.state.get("queryOptions");
        const startTime = `[${options.calendar.from.getTime() / 1000},${options.calendar.until.getTime() / 1000}]`;
        const filters = {
            plant_id: plantId, // eslint-disable-line camelcase
            range:    options.range,
            startTime,
            keyword:  `[${options.keyword.map(x => `"${x}"`).join(",")}]`,
            ...(messageGroup === translate("detail.plant.overall") ? {} : { messageGroup })
        };
        const queryString = createQueryString(filters);
        const monitorStatistic = await this.http("GET", `${window.config.backendUrl}/monitorStatistics?${queryString}`).then(x => x.json());

        this.state.select("monitorStatistic").set(plantId ? monitorStatistic : []);
        this.state.commit();
        this.trigger("unsetBusy");
    });

    fetchMonitorGraph = action(async(plantId, messageGroup, lazy = false) => {
        const cacheCursor = this.state.select(["stupidCache", "fetchMonitorGraph"]);

        if(lazy && cacheCursor.get()?.timestamp >= new Date().getTime() - 1000 * 30)
            return;

        cacheCursor.set({ timestamp: new Date().getTime() });

        this.trigger("setBusy");
        const options = this.state.get("queryOptions");
        const translate = this.state.get("translate");

        await this.trigger("retrieve", "monitorGraph", "graphMessages", {
            startTime:    `[${options.calendar.from.getTime() / 1000},${options.calendar.until.getTime() / 1000}]`,
            plant_id:     plantId, // eslint-disable-line camelcase
            range:        options.range,
            messageGroup: messageGroup === translate("detail.plant.overall") ? "overall" : messageGroup,
            locale:       this.state.get("locale"),
            keyword:      `[${options.keyword.map(x => `"${x}"`).join(",")}]`
        });
        this.state.commit();
        this.trigger("unsetBusy");
    });

    fetchUngrouped = action(async(id, reset = true) => { // eslint-disable-line max-statements
        this.trigger("setBusy");
        const plantId      = id ?? window.location.pathname.split("?")[0].split("/").slice(-1);
        const { calendar:calendarElem, keyword } = this.state.get(["queryOptions"]);
        const sortOptions = this.state.get("ungroupedSorting");
        const filters = {
            startTime: `[${calendarElem.from.getTime() / 1000},${calendarElem.until.getTime() / 1000}]`,
            plant_id:  isUuid(plantId) ? plantId : uuid(), // eslint-disable-line
            order:     sortOptions.order,
            orderKey:  sortOptions.property,
            keyword:   `[${keyword.map(x => `"${x}"`).join(",")}]`
        };
        const queryString = createQueryString(filters);

        const { count } = await this.http("GET", `${window.config.backendUrl}/datatable/ungrouped/count?${queryString}`).then(x => x.json());

        this.state.select(["pagination", "ungrouped", "overall"]).set(parseInt(count, 10));
        await this.trigger("fetchGroups");

        await this.pull("ungrouped", filters, reset);

        this.trigger("unsetBusy");
    });

    fetchGroups = action(async() => {
        const groups = await this.http("GET", `${window.config.backendUrl}/groups`).then(x => x.json());

        this.state.select("groups").set(groups.map(x => ({
            id:   uuid(),
            name: x
        })));
        this.state.commit();
    });

    fetchGroupsGraphs = action(async(plantId, groups) => {
        if(!plantId || !groups) return;

        const { calendar:calendarElem, keyword, range } = this.state.get(["queryOptions"]);
        const locale = this.state.get("locale");

        const fetchPromises = groups.map(group =>
            this.http("GET", `${window.config.backendUrl}/monitorGraph?startTime=[${calendarElem.from.getTime() / 1000},${calendarElem.until.getTime() / 1000}]&plant_id=${plantId}&range=${range}&messageGroup=${group.messageGroup}&locale=${locale}&keyword=[${keyword.map(x => `"${x}"`).join(",")}]`)
                .then(response => response.json())
                .then(groupsDataFetch => ({
                    messageGroup: group.messageGroup,
                    data:         groupsDataFetch
                }))
        );

        const allData = await Promise.all(fetchPromises);

        this.state.select("groupsGraph").set(allData);
        this.state.commit();
        return;
    });

    setUngroupedKeywords = action(keywords => {
        this.trigger("applyOption", "keyword", keywords);
    });

    addGroups = action(async groups => {
        const previous  = this.state.get("groups");
        const prevNames = previous.map(y => y.name);
        const fresh     = groups.filter(x => !prevNames.includes(x));
        const allGroups = previous.concat(fresh).map(x => ({
            id:   x.id || uuid(),
            name: x.name || x
        }));

        this.state.select("groups").set(allGroups);
        this.state.commit();
    });

    retrieveAsync = action(async(type, target, filters = {}) => {
        this.trigger("setBusy");
        const queryString = createQueryString(filters);
        const url         = `${window.config.backendUrl}/${type}?${queryString}`;

        try {
            const result = await this.http("GET", url);
            const data   = await result.json();

            this.setState(target, data);
            this.trigger("unsetBusy");
        } catch(error) {
            this.state.select("failedRequest").set(this.state.get("failedRequest").concat([{ type, target, filters }]));
            this.state.commit();
            if(type === "overview") {
                this.log.error(error);
                return;
            }
        }
    });

    applyQuery = action((options, keys) => { // eslint-disable-line complexity
        const optionKeys = keys ?? [...new URLSearchParams(window.location.search).keys()];
        const selectedPlant = this.state.get("selectedPlant");
        const plantId     = isUuid(options.selectedPlant?.id) ? options.selectedPlant?.id : selectedPlant?.id;
        const content     = {
            startTime:          `[${options.calendar.from.getTime() / 1000},${options.calendar.until.getTime() / 1000}]`,
            messageType:        options.messageTypes.map(elem => `"${elem}"`),
            ...["limit", "mode", "type", "range", "timeframes"].reduce((dest, x) => options[x] ? ({ ...dest, [x]: options[x] }) : dest, {}),
            ...["keyword"].reduce((dest, x) => options[x] ? ({ ...dest, [x]: (options[x] ?? []).filter(y => y).map(elem => `"${elem}"`) }) : dest, {}),
            mainGraphViewTypes: options.mainGraphViewTypes?.map(elem => `"${elem}"`)
        };

        if(optionKeys.length === 0) return this.redirect(plantId);

        const queryString = createQueryString(optionKeys.reduce((dest, x) => ({ ...dest, [x]: content[x] }), {}));

        return this.redirect(plantId, queryString);
    });

    redirect = (plantId, queryString) => {
        const [, main, prevPlantId, ...additional] = window.location.pathname.split("/");
        const plant = plantId ?? prevPlantId;

        if(plantId === prevPlantId && !queryString) return null;

        return this.trigger("open", `/${main}${plant ? `/${plant}` : ""}/${additional.join("/")}?${queryString ?? ""}`);
    };

    fetchObservations = action(id => {
        if(!id) return;

        this.trigger("setBusy");
        const options = this.state.get("queryOptions");

        this.trigger("retrieve", "observation", ["observation", "messages"], {
            startTime: `[${options.calendar.from.getTime() / 1000},${options.calendar.until.getTime() / 1000}]`,
            plant_id:  id, // eslint-disable-line camelcase
            range:     options.range
        });

        this.trigger("unsetBusy");
    });

    fetchGraph = action(filters => {
        const options = this.state.get("queryOptions");
        const plant   = filters.plantId ?? location.pathname.split("?")[0].split("/").slice(-1)[0];

        const fullFilters = {
            plant_id:  plant, // eslint-disable-line camelcase
            startTime: `[${options.calendar.from.getTime() / 1000},${options.calendar.until.getTime() / 1000}]`,
            ...filters
        };

        this.trigger("retrieve", "history", ["sidebarContent", "history", "messages"], fullFilters);
        this.trigger("retrieve", "sidebar/graph", ["sidebarContent", "graph"], fullFilters);
    });

    fetchObsGraph = action(filters => {
        const options = this.state.get("queryOptions");
        const plantId = filters.plant_id ?? options.selectedPlant?.id; // eslint-disable-line camelcase

        if(!plantId) return;

        const fullFilters = {
            ...filters,
            plant_id:  plantId, // eslint-disable-line camelcase
            startTime: `[${options.calendar.from.getTime() / 1000},${options.calendar.until.getTime() / 1000}]`
        };

        this.trigger("retrieve", "line/graph", ["observation", "graph"], fullFilters);
    });

    downloadCSV = action(async url => {
        const token   = await this._manager.get();
        const options = {
            headers: {
                "Accept":        "application/json",
                "Content-Type":  "application/json",
                "Authorization": token ? `Bearer ${token.bearer}` : null
            }
        };

        const res = await fetch(url, options);

        streamSaver.mitm = "/mitm.html";

        if(!window.WritableStream) streamSaver.WritableStream = WritableStream;

        const fileName   = res.headers.get("Content-disposition").split("=").slice(-1)[0];
        const fileStream = streamSaver.createWriteStream(fileName);

        if(res.body.pipeTo) return res.body.pipeTo(fileStream);

        const writer = fileStream.getWriter();
        const reader = res.body.getReader();

        const pump = () => reader.read()
            .then(({ value, done }) => {
                if(done) return writer.close();

                writer.write(value);
                return writer.ready.then(pump);
            });

        return await pump();
    });

    fetch = action(async(id, options = {}, messageGroup = "overall", isLazy = false) => {
        const cacheCursor = this.state.select(["stupidCache", "fetch", id, messageGroup]);

        if(isLazy && cacheCursor.get()?.timestamp >= new Date().getTime() - 1000 * 30)
            return null;

        cacheCursor.set({ timestamp: new Date().getTime() });

        const plant        = id ?? window.location.pathname.split("?")[0].split("/").slice(-1)[0];
        const monitor      = this.state.get("monitor");
        const { calendar: calendarElem, range, messageTypes, keyword, mode } = this.state.get(["queryOptions"]);
        const translate = this.state.get("translate");
        const monitorSortingSettings = this.state.get("monitorSortingSettings");

        const filters = {
            startTime:    `[${calendarElem.from.getTime() / 1000},${calendarElem.until.getTime() / 1000}]`,
            plant_id:     isUuid(plant) ? plant : uuid(),// eslint-disable-line
            keyword:      `[${keyword.filter(x => x).map(elem => encodeURIComponent(`"${elem}"`)).join(",")}]`,
            range:        range,
            messageType:  messageTypes.map(elem => `"${elem}"`),
            messageGroup: messageGroup === translate("detail.plant.overall") ? "overall" : messageGroup,
            order:        monitorSortingSettings.order,
            orderKey:     monitorSortingSettings.selected
        };

        const request = mode === "filter" ?
            monitor.isOpen.map(elem => this.pullFilter(elem, filters, options.reset)).concat(await this.trigger("retrieve", "datatable/overall", ["monitor", "groups"], filters)) :
            [this.trigger("pullMessages", plant, messageGroup, filters, options.reset)];

        await request;

        if(options.onlyDatatable) return null;

        return createQueryString({
            ...filters,
            messageType: this.state.get(["queryOptions", "messageTypes"]).map(elem => `"${elem}"`)
        });
    });

    applyOption = action((type, value) => {
        const resolveValue = () => {
            if(type === "calendar" && value?.from && value?.until)
                return DatePicker.of([value.from, value.until]);


            return value;
        };

        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            [type]: resolveValue()
        };

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
        this.state.commit();

        return options;
    });

    retrieve = action(async(type, target, filters = {}) => {
        this.trigger("setBusy");

        const queryString = createQueryString(filters);
        const url         = `${window.config.backendUrl}/${type}?${queryString}`;

        try {
            const result = await this.http("GET", url);
            const data   = await result.json();

            this.trigger("unsetBusy");
            this.setState(target, data);
            this.state.commit();
        } catch(error) {
            this.state.select("failedRequest").set(this.state.get("failedRequest").concat([{ type, target, filters }]));
            this.state.commit();

            if(type === "overview")
                this.log.error(error);
            return;
        }
    });

    fetchOverview = action(async(range, startTime, retry = false) => {
        if(!retry)
            this.trigger("setBusy");

        const plants = this.state.get("plants");

        await Promise.all(plants.map(async x => {
            const filters = {
                plant_id: x.id, // eslint-disable-line camelcase
                range,
                startTime
            };
            const queryString = createQueryString(filters);
            const url    = `${window.config.backendUrl}/overview?${queryString}`;
            const result = await this.http("GET", url);
            const data   = await result.json();

            this.state.select(["summaries", x.id]).set(data);
        }));
        if(!retry)
            this.trigger("unsetBusy");
    });

    pullFilteredMessages = action(async({ startTime, endTime, plantId }) => {
        const result  = await this.http("GET", `${window.config.backendUrl}/datatable/messages?startTime=[${new Date(startTime).getTime() / 1000},${new Date(endTime).getTime() / 1000}]&plant_id=${plantId}&messageType=["err"]&order=desc&pageSize=1000&page=1`);
        const data    = await result.json();

        this.state.select("causeMessages").set(data);
        this.state.commit();
    });

    applyPlant = action(plantId => {
        const plant    = this.state.get("licensedPlants").find(x => x.id === plantId);
        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            selectedPlant: plant
        };

        this.state.select("selectedPlant").set(plant);
        this.state.select("monitor", "filter").set(null);

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
        this.state.commit();
    });

    applyDeleteSettings = action(newSettings => {
        const pointer = this.state.select("message_delete_settings");

        newSettings.map(setting => {
            const current = pointer.get().find(entry => entry.plant_id === setting.plant_id);

            if(!current)
                pointer.push(setting);
            else
                pointer.set(current, Object.assign({}, setting, current.id && {
                    id: current.id
                }));
        });

        this.state.commit();
    });

    pullMessages = action((plant, messageGroup, filters, reset) => {
        const selected = this.state.get(["queryOptions", "messageTypes"]);
        const type     = {
            messageType: selected
                .filter(x => x !== "log" || hasPermission(this.state.get("user"), "MESSAGE_MONITOR_ADMIN"))
                .map(elem => `"${elem}"`)
        };

        if(filters)
            return this.pull("messages", {
                order: "desc",
                ...filters,
                ...type
            }, reset, false);

        const id           = plant ?? window.location.pathname.split("?")[0].split("/").slice(-1);
        const { calendar: calendarElem, range, keyword } = this.state.get(["queryOptions"]);
        const { selected: orderKey, order } = this.state.get("monitorSortingSettings");
        const translate = this.state.get("translate");

        this.pull("messages", {
            ...type,
            startTime:    `[${calendarElem.from.getTime() / 1000},${calendarElem.until.getTime() / 1000}]`,
            plant_id:  isUuid(id) ? id : uuid(), // eslint-disable-line
            keyword:      `[${keyword.filter(x => x).map(elem => encodeURIComponent(`"${elem}"`)).join(",")}]`,
            messageGroup: messageGroup === translate("detail.plant.overall") ? "overall" : messageGroup,
            order,
            orderKey,
            range
        }, reset, false);

        return this.state.commit();
    });

    // --- helper ---

    toggleOrder = order => {
        if(order === "desc") return "asc";

        return "dest";
    };

    applyMessageType(key) {
        const previous = this.state.get("queryOptions");
        const types    = (previous.messageTypes ?? []);
        const value    = types.includes(key) ?
            types.filter(x => x !== key) :
            types.concat(key);
        const options = {
            ...previous,
            messageTypes: value
        };

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
        this.state.commit();

        return options.selectedPlant;
    }

    applyMainGraphView(types) {
        const previous = this.state.get("queryOptions");

        const options  = {
            ...previous,
            mainGraphViewTypes: types
        };

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
    }

    async pull(type, filters, reset = true, setGlobalBusy = true) { // eslint-disable-line
        const format         = value => typeof value === "string" ? value.replaceAll("+", "%2B") : value;
        const paginationInfo = this.state.get(["pagination", type]);
        const initialCount   = reset ? 10 : paginationInfo.count;
        const urlFilters = Object.assign({}, filters, {
            pageSize: App.limit,
            page:     !reset ? paginationInfo.page + 1 : 1
        });

        const query = Object.keys(urlFilters)
            .reduce((dest, val) => dest.concat(`${val}=${urlFilters[val] instanceof Array ? `[${urlFilters[val]}]&` : `${format(urlFilters[val])}&`}`), "").slice(0, -1);

        if(setGlobalBusy || reset) this.trigger("setBusy");

        const result  = await this.http("GET", `${window.config.backendUrl}/datatable/${type}?${query}`);
        const data    = await result.json();

        this.state.select(["pagination", type, "count"]).set(data.length === 10 ? initialCount + 10 : initialCount - 10 + data.length);
        this.state.select(["pagination", type, "page"]).set(parseInt(result.headers.get("Pagination-Page"), 10));
        this.state.commit();

        const ids      = data.map(elem => elem.id);
        const previous = reset ? [] : this.state.get(type).filter(elem => !ids.includes(elem.id));

        if(setGlobalBusy || reset) this.trigger("unsetBusy");

        this.setState(type, previous.concat(data));
        return;
    }

    async pullFilter(messageType, filters, reset = false) { // eslint-disable-line
        const pagination   = this.state.get(["pagination", "monitor", messageType]);
        const sorting      = this.state.get("monitor", "sorting");
        const metadata     = pagination ?? {
            count: 10,
            limit: App.limit,
            page:  0
        };
        const format  = value => typeof value === "string" ? value.replaceAll("+", "%2B") : value;
        const urlFilters = {
            ...filters,
            messageType: `"${messageType}"`,
            pageSize:    metadata.limit,
            page:        reset ? 1 : metadata.page + 1,
            order:       sorting.order
        };
        const query = Object.keys(urlFilters)
            .reduce((dest, val) => dest.concat(`${val}=${urlFilters[val] instanceof Array ? `[${urlFilters[val]}]&` : `${format(urlFilters[val])}&`}`), "").slice(0, -1);

        this.trigger("setBusy");
        const result = await this.http("GET", `${window.config.backendUrl}/datatable/grouping/${sorting.property}?${query}`);
        const data   = await result.json();

        this.state.select(["pagination", "monitor", messageType, "count"]).set(data.length === 10 ? metadata.count + 10 : metadata.count - 10 + data.length);
        this.state.select(["pagination", "monitor", messageType, "page"]).set(parseInt(result.headers.get("Pagination-Page"), 10));
        this.state.select(["pagination", "monitor", messageType, "limit"]).set(parseInt(result.headers.get("Pagination-Limit"), 10));
        const ids      = data.map(elem => elem.id);
        const previous = reset ? [] : this.state.get(["monitor", "aggregates", messageType]).filter(elem => !ids.includes(elem.id));
        const value    = previous.concat(data);

        this.trigger("unsetBusy");
        this.setState(["monitor", "aggregates", messageType], value);
        if(value.length > 0) return;
        this.setState(["monitor", "isOpen"], this.state.get(["monitor", "isOpen"]).filter(x => x !== messageType));
    }
}

export default App;

import { useEffect, useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { usePrevious } from "palstek";
import { useParams } from "react-router-dom";

import { Breadcrumb } from "../Breadcrumb";
import { TypeSelector } from "./TypeSelector";
import { useQuery } from "../../hooks/query";
import { useQueryString } from "../../hooks/querystring";
import { RangeSelector } from "../generic/rangeSelector/RangeSelector";
import { getRangeString } from "../../helper/date";
import { UngroupedList } from "./UngroupedList";
import { GroupDetails } from "./GroupDetails";
import { Icon, SwitchButton } from "@circle/kip-components";

import styles from "./detailview.module.scss";

const DetailView = () => { // eslint-disable-line max-statements
    const { trigger }                    = useTrigger();
    const { translate, currentLanguage } = useTranslation();
    const params                         = useParams();
    const [filterView, setFilterView]  = useState("all");

    const { plant, options, plants, user, observedGroups } = useBranch({
        observedGroups: ["observedGroups"],
        plant:          ["selectedPlant"],
        options:        ["queryOptions"],
        plants:         ["plants"],
        user:           ["user"]
    });

    const prevOpts = usePrevious(options);
    const onTypeSelect = type => {
        const relevantTypes = options.messageTypes;

        if(relevantTypes.includes(type) && relevantTypes.length === 1) return;

        trigger("applyOption", "messageTypes", options.messageTypes.includes(type) ? options.messageTypes.filter(x => x !== type) : options.messageTypes.concat(type));
    };

    const onDatePick = date => {
        trigger("applyOption", "range", date.name);
        trigger("applyOption", "calendar", date);
    };

    const onTimeframeChange = val => {
        trigger("applyOption", "timeframes", val);
    };

    const [filter] = useQueryString({
        options:  options,
        plants:   plants,
        optional: {
            timeframes: () => options.timeframes || "shifts",
            keyword:    x => {
                try {
                    return x ? JSON.parse(x) : [];
                } catch{
                    return [];
                }
            }
        }
    });

    const [init] = useQuery({
        options: filter,
        keys:    ["startTime", "keyword", "messageType", "range", "timeframes"]
    });

    const switchSavedFilter = e => {
        setFilterView(e.target.value);
    };

    useEffect(() => {
        trigger("applyPlant", params.plantId);
    }, []);

    useEffect(() => {
        const isLazy = prevOpts?.messageTypes?.length !== options?.messageTypes?.length;

        trigger("fetchMessageGroups", isLazy);
    }, [options.calendar, options.keyword, options.range, options.messageTypes]);

    useEffect(() => {
        if(observedGroups.length === 0) {
            setFilterView("all");
            return;
        }
        return;
    }, [observedGroups]);

    return (
        <>
            { init &&
                <div className={styles.detailView}>
                    <div className={styles.breadcrumb}>
                        <div className={styles.breadcrumbContainer}>
                            <Breadcrumb items={[{ value: translate(plant?.name), path: `/overview/${plant?.id}`, index: 0 }]}/>
                        </div>
                        <RangeSelector
                            range={options.range}
                            selected={options.calendar}
                            timeframes={options.timeframes}
                            onChange={onDatePick}
                            onTimeframeChange={onTimeframeChange}
                        />
                    </div>
                    <div className={styles.plant}>
                        <div className={styles.logo}>
                            <img src={plant?.manufacturer?.logo ?? "/images/Bitmap.png"}/>
                        </div>
                        <div className={styles.info}>
                            <div className={styles.plantName}>
                                <span>{ translate(plant?.name) }</span>
                                <span className={styles.slot}>{ options.range === "shift" ? translate("overview.selector.shift") : getRangeString([options.calendar.from, options.calendar.until], currentLanguage)}</span>
                            </div>
                            <span className={styles.manufacturer}>{ plant?.manufacturer?.name }</span>
                        </div>
                    </div>
                    <div className={styles.header}>
                        <div className={styles.selector}>
                            <TypeSelector onClick={() => onTypeSelect("err")} type="err" active={options.messageTypes.includes("err")}/>
                            <TypeSelector onClick={() => onTypeSelect("task")} type="task" active={options.messageTypes.includes("task")}/>
                            <TypeSelector onClick={() => onTypeSelect("warn")} type="warn" active={options.messageTypes.includes("warn")}/>
                        </div>
                        {
                            observedGroups.length !== 0 ?
                                <SwitchButton
                                    onChange={e => switchSavedFilter(e)}
                                    options={[
                                        {
                                            value:   "all",
                                            checked: filterView === "all",
                                            label:   <>
                                                <span>{translate("filter.all")}</span></>
                                        },
                                        {
                                            value:   "myList",
                                            checked: filterView === "myList",
                                            label:   <><Icon _icon="Favorite" />{" "}
                                                <span>{translate("filter.my.list")}</span></>
                                        }
                                    ]}
                                /> :
                                <></>
                        }
                    </div>
                    <GroupDetails typeSelectorStatus={options.messageTypes} filterView={filterView} />
                    <UngroupedList/>
                </div>
            }
        </>
    );
};

export { DetailView };

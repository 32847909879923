import { useEffect, useState } from "react";
import { useBranch, useTranslation, useTrigger, hasPermission } from "@circle/gestalt-app";
import { Button, Icon } from "@circle/kip-components";
import { useParams } from "react-router-dom";
import { resolveClassNames } from "palstek";
import { Accordeon } from "../generic/Accordeon";
import { Checkbox } from "../generic/Checkbox";
import icons from "../../enums/icons";
import { InfiniteList } from "../generic/InfiniteList";
import { BusyIndicator } from "../BusyIndicator";
import { GroupSelector } from "./GroupSelector";
import { MultiSelect } from "../generic/MultiSelect";
import { v4 as uuid } from "uuid";

import styles from "./ungroupedlist.module.scss";

const UngroupedList = () => { // eslint-disable-line complexity,max-statements
    const params = useParams();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [singleMessage, setSingleMessage] = useState(null);
    const [active, setActive] = useState(null);
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const { sorting, ungrouped, pagination, options, user, selectedPlant } = useBranch({
        sorting:       ["ungroupedSorting"],
        ungrouped:     ["ungrouped"],
        pagination:    ["pagination", "ungrouped"],
        options:       ["queryOptions"],
        user:          ["user"],
        selectedPlant: ["selectedPlant"]
    });

    const grouping  = hasPermission(user, "MESSAGE_MONITOR_GROUP_MESSAGES");

    const onSelectAll = () => {
        setSelected(selected.includes(null) ? [] : selected.concat([null].concat(ungrouped.map(x => x.id))));
    };

    const onSelect = id => {
        setSelected(selected.includes(id) ? selected.filter(x => x !== id) : selected.concat(id));
    };

    const onRowClick = (e, x) => {
        e.stopPropagation();
        if(!grouping || e.target.tagName.toUpperCase() === "BUTTON") return;

        onSelect(x);
    };

    const createMessageToSend = (baseMessage, name) => ({
        plant_id:              baseMessage.plant_id, // eslint-disable-line camelcase
        referenceObject_Sign:  baseMessage.referenceObject_Sign, // eslint-disable-line camelcase
        referenceObject_Descr: baseMessage.referenceObject_Descr, // eslint-disable-line camelcase
        system:                baseMessage.system,
        message:               baseMessage.message,
        messageType:           baseMessage.messageType,
        name
    });

    const onSubmit = messageGroups => {
        const mapToMessageToSend = message => ({
            plant_id:              selectedPlant.id, // eslint-disable-line camelcase
            referenceObject_Sign:  message.referenceObject_Sign, // eslint-disable-line camelcase
            referenceObject_Descr: message.referenceObject_Descr, // eslint-disable-line camelcase
            system:                message.system,
            message:               message.message,
            messageType:           message.messageType
        });

        if(singleMessage) {
            const message = ungrouped.find(obj => obj.id === singleMessage);

            const messageToSend = mapToMessageToSend(message);

            const groupsToApply = messageGroups.map(group =>
                createMessageToSend(messageToSend, group.name)
            );

            setSingleMessage(null);
            trigger("applyMessageGroups", groupsToApply, [message]);
            setActive(null);
            return;
        }

        const messages = ungrouped.filter(obj => selected.includes(obj.id));
        const messageToSend = messages.map(mapToMessageToSend);

        const groupsToApply = messageToSend.flatMap(message =>
            messageGroups.map(group => createMessageToSend(message, group.name))
        );

        trigger("applyMessageGroups", groupsToApply, messages);
        setActive(null);
        return;
    };

    const closeModal = () => {
        setActive(null);
        setSingleMessage(null);
    };

    const onFetch = () => {
        trigger("fetchUngrouped", params.plantId, false);
    };

    const onSearch = values => {
        if(options.keyword.length === values.length && options.keyword.reduce((dest, keyword) => dest && values.includes(keyword), true)) return;

        trigger("setUngroupedKeywords", values.map(x => x.value));
    };

    useEffect(() => {
        trigger("fetchUngrouped", params.plantId, true);
    }, [options, sorting]);

    return (
        (pagination?.overall !== 0 || options?.keyword?.length !== 0) &&
        <div className={styles.messageList}>
            <Accordeon
                prefix=""
                isOpen={open}
                className={styles.accordeon}
                header={
                    <div className={styles.accordeonHeader} onClick={() => setOpen(!open)}>
                        <div className={styles.accordeonTitle}>
                            <span>
                                {pagination.overall ?? 0 }
                                {" "}
                                {pagination.overall === 1 ? translate("detail.message.ungrouped1") : translate("detail.message.ungrouped") }
                            </span>
                        </div>
                        <Icon _icon={open ? "ChevronUp" : "ChevronDown"}/>
                    </div>
                }
            >
                <div className={styles.refContainer}>
                    <GroupSelector isActive={active !== null} onSubmit={messageGroups => onSubmit(messageGroups, active)} onClose={closeModal}/>
                    {
                        open &&
                        <>
                            <MultiSelect initialValue={options.keyword.map(x => ({ id: uuid(), value: x }))} onChange={onSearch}/>
                            <table className={styles.messageTable}>
                                <thead>
                                    <tr>
                                        {
                                            grouping &&
                                            <th style={{ width: "3rem" }}><Checkbox checked={selected.includes(null)} onChange={onSelectAll}/></th>
                                        }
                                        <th style={{ width: "3rem" }} className="clickable" onClick={() => trigger("sortUngroupedMessages", params.plantId, params.messageGroup, {
                                            property: "messageType",
                                            order:    sorting.order !== "desc" ? "desc" : "asc"
                                        })}>
                                            <span>{ translate("message.messageType") }
                                                {
                                                    sorting.property === "messageType" &&
                                                <Icon className={styles.filterArrow} _icon={sorting.order === "desc" ? "ArrowDown" : "ArrowUp"}/>
                                                }
                                            </span></th>
                                        <th style={{ flex: 1 }} className="clickable" onClick={() => trigger("sortUngroupedMessages", params.plantId, params.messageGroup, {
                                            property: "system",
                                            order:    sorting.order !== "desc" ? "desc" : "asc"
                                        })}>
                                            <span>{ translate("message.system") }
                                                {
                                                    sorting.property === "system" &&
                                                    <Icon className={styles.filterArrow} _icon={sorting.order === "desc" ? "ArrowDown" : "ArrowUp"}/>
                                                }
                                            </span></th>
                                        <th style={{ flex: 2 }} onClick={() => trigger("sortUngroupedMessages", params.plantId, params.messageGroup, {
                                            property: "referenceObject",
                                            order:    sorting.order !== "desc" ? "desc" : "asc"
                                        })}>
                                            <span>{ translate("message.refObj") }
                                                {
                                                    sorting.property === "referenceObject" &&
                                                    <Icon className={styles.filterArrow} _icon={sorting.order === "desc" ? "ArrowDown" : "ArrowUp"}/>
                                                }
                                            </span></th>
                                        <th style={{ flex: 2 }} onClick={() => trigger("sortUngroupedMessages", params.plantId, params.messageGroup, {
                                            property: "message",
                                            order:    sorting.order !== "desc" ? "desc" : "asc"
                                        })}>
                                            <span>{ translate("message.text") }
                                                {
                                                    sorting.property === "message" &&
                                                    <Icon className={styles.filterArrow} _icon={sorting.order === "desc" ? "ArrowDown" : "ArrowUp"}/>
                                                }
                                            </span></th>
                                        {
                                            grouping &&
                                    <th style={{ width: "8rem" }}>
                                        <div className={styles.groupsHeader}>
                                            <span>{ translate("message.groups") }</span>
                                            <Button _appearance={active === -1 ? "primary" : "default"} className={resolveClassNames(styles.button, styles.headerButton, selected.filter(x => x).length > 0 && styles.visible)} _variant="icon" onClick={e => {
                                                e.stopPropagation();
                                                setActive(active === -1 ? null : -1);
                                            }}>
                                                <Icon _icon="Add"/>
                                            </Button>
                                        </div>
                                    </th>
                                        }
                                    </tr>
                                </thead>
                                <InfiniteList
                                    metadata={pagination}
                                    onFetch={onFetch}
                                    element="tbody"
                                    placeholder={<tr className="empty"><td colSpan={ungrouped.length + 1}>{ translate("datatable.empty") }</td></tr>}
                                    loader={() => <tr><td colSpan="7" width="100%"><BusyIndicator /></td></tr>}
                                >
                                    {
                                        ungrouped.map((x, key) => (
                                            <tr className={resolveClassNames(styles.rowContainer, !grouping && styles.inactive)} key={x.id} onClick={e => onRowClick(e, x.id)}>
                                                {
                                                    grouping &&
                                                    <td style={{ width: "3rem" }}><Checkbox className={styles.unclickableCheckbox} checked={selected.includes(x.id)}/></td>
                                                }
                                                <td style={{ width: "3rem" }}><Icon className={styles[`icon-${x.messageType}`]} _icon={icons[x.messageType]}/></td>
                                                <td style={{ flex: 1 }}><span>{ x.system }</span></td>
                                                <td style={{ flex: 2 }}><span>{ x.referenceObject }</span></td>
                                                <td style={{ flex: 2 }}><span>{ x.message }</span></td>
                                                <td style={{ width: "8rem" }}>
                                                    {
                                                        grouping &&
                                                        <Button _appearance={active === key ? "primary" : "default"} className={styles.button} _variant="icon" onClick={e => {
                                                            e.stopPropagation();
                                                            setSingleMessage(x.id);
                                                            setActive(active === key ? null : key);
                                                        }}>
                                                            <Icon _icon="Add"/>
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </InfiniteList>
                            </table>
                        </>
                    }
                </div>
            </Accordeon>
        </div>
    );
};

export { UngroupedList };

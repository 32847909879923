/* eslint-disable no-undefined */
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { Button, Icon, OverflowContainer } from "@circle/kip-components";
import PropTypes from "prop-types";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { resolveClassNames } from "palstek";

import { Progress } from "./Progress";
import { SortableHeader } from "./SortableHeader";

import styles from "./groupdetails.module.scss";


const GroupDetails = ({ typeSelectorStatus, filterView }) => { // eslint-disable-line complexity
    const { translate } = useTranslation();
    const { trigger }   = useTrigger();
    const params        = useParams();

    const { options, groups, selectedPlant, groupsGraph, observedGroups } = useBranch({
        observedGroups: ["observedGroups"],
        selectedPlant:  ["selectedPlant"],
        options:        ["queryOptions"],
        groups:         ["sortedGroups"],
        groupsGraph:    ["groupsGraph"]
    });

    const overall = (groups ?? []).filter(x => x.messageGroup === null);
    const others = (groups ?? []).filter(x => x.messageGroup !== null);

    const onRowClick = (val, e) => {
        e.preventDefault();

        trigger("open", `/monitor/${options.selectedPlant.id}/${val}`);
    };

    const onFavorite = (e, elem, exist) => {
        e.stopPropagation();
        if(exist) {
            trigger("deleteObservedGroup", elem.messageGroup);
            return;
        }
        trigger("addObservedGroup", selectedPlant?.id ?? params?.plantId, elem.messageGroup);
        return;
    };

    const content = filterView === "myList" ? overall.concat(observedGroups) : overall.concat(others);

    const messageGroupGraph = group => {
        if(!groupsGraph) return [];
        return groupsGraph.find(x => x.messageGroup === group)?.data;
    };

    const messageGroups = observedGroups.map(x => x.messageGroup);

    useEffect(() => {
        if(filterView === "myList") {
            trigger("fetchGroupsGraphs", selectedPlant?.id ?? params?.plantId, observedGroups, true);
            return;
        }
        return;
    }, [options, filterView]);

    return (
        <div className={styles.table}>
            <div className={styles.tableHeader}>
                <div className={styles.column} />
                {typeSelectorStatus.includes("err") && <SortableHeader isVisible={true} type="err" />}
                {typeSelectorStatus.includes("task") && <SortableHeader isVisible={true} type="task" />}
                {typeSelectorStatus.includes("warn") && <SortableHeader isVisible={true} type="warn" />}

                <div className={styles.rowIcon} />
            </div>
            {
                <OverflowContainer className={styles.scrollView}>
                    {
                        content.map((x, key) => { // eslint-disable-line complexity
                            return (
                                <div key={key} className={styles.rowContainer}>
                                    <div className={resolveClassNames(styles.row, !x.messageGroup && styles.overall)} onClick={e => onRowClick(x.messageGroup ?? translate("detail.plant.overall"), e)}>
                                        <div className={styles.column}><div className={styles.title} title={x.messageGroup}>
                                            {x.messageGroup ? <Button className={styles.favoriteButton} _appearance={messageGroups.includes(x.messageGroup) && "primary"} _variant="icon" onClick={e => onFavorite(e, x, messageGroups.includes(x.messageGroup))}><Icon _icon="Favorite" /></Button> : <div></div>}
                                            {x.messageGroup ?? translate("detail.plant.overall")}</div></div>
                                        {
                                            typeSelectorStatus.includes("err") ?
                                                <div className={styles.column}
                                                    data-columncount={typeSelectorStatus.length}>
                                                    {((_type = "err") => {
                                                        const res = x.items.find(item => item.messageType === _type);

                                                        return <Progress
                                                            duration={res?.duration}
                                                            ratio={res?.ratio}
                                                            tendency={res?.tendency}
                                                            hideBar={!x.messageGroup} type={_type} />;
                                                    })()}
                                                </div> :
                                                <div className={styles.antiColumn}>
                                                </div>
                                        }
                                        {
                                            typeSelectorStatus.includes("task") ?
                                                <div className={styles.column}
                                                    data-columncount={typeSelectorStatus.length}>
                                                    {((_type = "task") => {
                                                        const res = x.items.find(item => item.messageType === _type);

                                                        return <Progress
                                                            duration={res?.duration}
                                                            ratio={res?.ratio}
                                                            tendency={res?.tendency}
                                                            hideBar={!x.messageGroup} type={_type} />;
                                                    })()}
                                                </div> :
                                                <div className={styles.antiColumn}>
                                                </div>
                                        }
                                        {
                                            typeSelectorStatus.includes("warn") ?
                                                <div className={styles.column}
                                                    data-columncount={typeSelectorStatus.length}>
                                                    {((_type = "warn") => {
                                                        const res = x.items.find(item => item.messageType === _type);

                                                        return <Progress
                                                            duration={res?.duration}
                                                            ratio={res?.ratio}
                                                            tendency={res?.tendency}
                                                            hideBar={!x.messageGroup} type={_type} />;
                                                    })()}
                                                </div> :
                                                <div className={styles.antiColumn}>
                                                </div>
                                        }
                                        <div className={styles.rowIcon}>
                                            <Icon _icon="ChevronRight" />
                                        </div>
                                    </div>

                                    {(filterView === "myList" && x.messageGroup) ?
                                        <div className={styles.graphRow}>
                                            <div className={styles.column} />
                                            <div className={styles.graphContainer}>
                                                <ResponsiveContainer width="100%" height={180}>
                                                    <BarChart
                                                        width={100}
                                                        height={70}
                                                        data={messageGroupGraph(x.messageGroup)}
                                                    >
                                                        <CartesianGrid stroke="#D7D7D7" />
                                                        <XAxis dataKey="name" />
                                                        <YAxis yAxisId="left" tickFormatter={value => `${Math.floor(value / 60)} min`} domain={[0, 60 * 60]} />

                                                        {
                                                            typeSelectorStatus.includes("err") &&
                                                        <Bar yAxisId="left" dataKey="errorDuration" fill="#EB6060" />
                                                        }
                                                        {
                                                            typeSelectorStatus.includes("task") &&
                                                        <Bar yAxisId="left" dataKey="taskDuration" fill={"#FF9966"} />
                                                        }
                                                        {
                                                            typeSelectorStatus.includes("warn") &&
                                                        <Bar yAxisId="left" dataKey="warningDuration" fill={"#F7E463"} />
                                                        }
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                            <div className={styles.rowIcon} />
                                        </div> :
                                        <></>
                                    }
                                </div>
                            );
                        })
                    }
                </OverflowContainer>
            }
        </div>
    );
};

GroupDetails.propTypes = {
    typeSelectorStatus: PropTypes.array.isRequired,
    filterView:         PropTypes.string
};

export { GroupDetails };

import PropTypes from "prop-types";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import { Link } from "react-router-dom";
import styles from "./overview.module.scss";
import { OverviewStat } from "./OverviewStat";
import { CauseModal } from "../cause/CauseModal";

const OverviewItem = props => {
    const { translate } = useTranslation();
    const { data } = useBranch({
        data: ["summaries", props.plant.id]
    });

    return (
        <Link to={`/overview/${props.plant.id}${window.location.search}`} className={styles.item}>
            <div className={styles.itemContent}>
                <img className={styles.logo} src={props.plant.manufacturer.logo ?? "/images/Bitmap.png"} loading="lazy" alt="Manufacturer Logo" />
                <div className={styles.titles}>
                    <span className={styles.plantName}>
                        {
                            (translate(props.plant.name) || "").length > 24 ?
                                `${translate(props.plant.name).substring(0, 24)}...` :
                                translate(props.plant.name)
                        }
                    </span>
                    <span>
                        {translate(props.plant.location)}
                    </span>
                </div>
                <OverviewStat type="errors" data={data?.err}/>
                <OverviewStat type="tasks" data={data?.task}/>
                <CauseModal
                    selectedPlant={props.plant}
                    className={styles.ButtonMargin}
                />
            </div>
        </Link>
    );
};

OverviewItem.propTypes = {
    plant: PropTypes.object.isRequired
};

export { OverviewItem };
